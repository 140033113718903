<template>
  <div>
    <v-form>
      <v-row class="justify-center">
        <v-col cols="12" md="4">
          <v-card>
            <v-card-title>Change Password</v-card-title>
            <v-card-text>
              <v-text-field
                type="password"
                label="Current Password"
                placeholder="Current Password"
                v-model="form.current_password"
                required
                :error-messages="errors.current_password"
              />
              <v-text-field
                type="password"
                label="New Password"
                placeholder="New Password"
                v-model="form.password"
                required
                :error-messages="errors.password"
              />
              <v-text-field
                type="password"
                label="Confirm New Password"
                placeholder="Confirm New Password"
                v-model="form.password_confirmation"
                required
                :error-messages="errors.password_confirmation"
              />
              <v-row>
                <v-spacer/>
                <v-btn outlined class="ml-2" color="primary" @click="changePassword">Change</v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar.active" :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar.active = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
    export default {
      data() {
        return {
          form: {
            current_password: null,
            password: null,
            password_confirmation: null,
          },
          errors: {
            current_password: [],
            password: [],
            password_confirmation: [],
          },
          loading: false,
          snackbar: {
            active: false,
            message: null,
            timeout: -1,
            color: null
          },
        };
      },
      methods: {
        async changePassword() {

          this.loading = true;
          try {

            let {data} = await axiosBackend.post('/change-password', this.form);

            this.form = {current_password: null, password: null, password_confirmation: null};
            this.errors = {current_password: [], password: [], password_confirmation: []};

            this.loading = false;
            this.snackbar = {
              active: true,
              timeout: 5000,
              message: data.message,
            };
          } catch(error) {

            this.loading = false;

            if (error.response.status === 422) this.errors = error.response.data.errors;

            this.snackbar = {
              active: true,
              color: 'error',
              timeout: 5000,
              message: error.response.data.message,
            };
          }
        }
      }
    }
</script>
